












































































import Vue from 'vue';
import { PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'photovoltaics-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
  },
  data() {
    return {
      key: PHOTOVOLTAIK_INSURANCE,
      versicherungsort: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        ZursZone: '',
        Naturgefahrenklasse: '',
        ArtDerAnlage: null,
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData'],
  methods: {
    setValues(basicData: any) {
      this.versicherungsort = basicData.versicherungsort;
    },
    onSubmit() {
      this.submitForm();
    },
    submitForm(goNextPage = true) {
      const data = {
        versicherungsort: this.versicherungsort,
      };

      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData && this.insuranceData.basicData) {
      this.setValues(this.insuranceData.basicData);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
  },
});
